document.addEventListener('DOMContentLoaded', function () {
  console.log('active');
  var links = document.querySelectorAll(
    '.list_nav_item_link, .footer_list_link',
  );

  links.forEach(function (link) {
    link.addEventListener('click', function (e) {
      e.preventDefault();

      const target = link.getAttribute('href');

      links.forEach(function (el) {
        el.classList.remove('active');
      });

      // Добавьте активный класс к выбранной ссылке в хедере и футере
      const headerLink = document.querySelector(
        '.list_nav_item_link[href="' + target + '"]',
      );
      const footerLink = document.querySelector(
        '.footer_list_link[href="' + target + '"]',
      );
      if (headerLink) {
        headerLink.classList.add('active');
      }
      if (footerLink) {
        footerLink.classList.add('active');
      }
    });
  });
});
